import { useEffect, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle, Grid, GridItem, Link, Text
} from "@chakra-ui/react";


function Private() {
    const [appParam, setAppParam] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const appValue = searchParams.get('app');
        setAppParam(appValue);
        console.log('App param:', appValue);
    }, []);

    return (
        <Alert
            status='warning'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
        >
            <AlertIcon boxSize='40px' mr={0}/>
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                This account is private.
            </AlertTitle>
            {appParam === null ? (
                <AlertDescription>
                    <Text fontSize='sm'>
                        To view stories of this account anonymously, you should install one of our apps:
                    </Text>
                    <Grid
                        templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
                        gap={4}
                        mt={6} >

                        <GridItem>
                            <Link
                                isExternal
                                href="https://chromewebstore.google.com/detail/ghostify-instagram-story/ajkkboilpcbfgojiochgfbeblbpcgpga?utm_source=getghostify.com_prv&utm_medium=website&utm_campaign=ghostify%20website"
                                w="100%" className="store-btn-link" >
                                <img
                                    alt="Ghostify Instagram Story Viewer chrome extension download in chrome web store"
                                    src="https://cdn.dorik.com/621a4f7fad031d00128d2a39/images/downloadchromestorebtn80x283-FgWVC.png"
                                    loading="lazy"className="store-btn-img"  >
                                </img>
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link
                                isExternal
                                href="https://apps.apple.com/app/ghostify-story-viewer/id6737749873?pt=126998101&ct=getghostify.com_prv&mt=8"
                                w="100%" className="store-btn-link" >
                                <img
                                    alt="Ghostify Instagram Story Viewer iOS app in Apple Appstore"
                                    src="https://cdn.dorik.com/621a4f7fad031d00128d2a39/images/downloadontheappstore80x240-0tpWX.png"
                                    loading="lazy" className="store-btn-img" >
                                </img>
                            </Link>
                        </GridItem>
                        <GridItem>
                            <Link
                                isExternal
                                href="https://play.google.com/store/apps/details?id=com.getghostify&pli=1&utm_source=getghostify.com_prv&utm_medium=website&utm_campaign=ghostify%20website"
                                w="100%" className="store-btn-link" >
                                <img
                                    alt="Ghostify Instagram Story Viewer Android app in Google Play Store"
                                    src="https://cdn.dorik.com/621a4f7fad031d00128d2a39/images/downloadonthegoogleplay80x263-J8E7N.png"
                                    loading="lazy" className="store-btn-img" >
                                </img>
                            </Link>
                        </GridItem>
                    </Grid>
                </AlertDescription>
            ) : null}
        </Alert>
    )
}

export default Private
