import {Box, CircularProgress, Flex, Heading, VStack} from "@chakra-ui/react";
import HighlightItem from "./HighlightItem";
import Error from "./Error";
import useSWR from "swr";


function Highlights({username}) {

    const {
        data,
        error,
        isLoading
    } = useSWR("https://ext-api.getghostify.com/scraper/get_ig_user_highlights.php?username_or_url=" + username + "&source=3&1",
        (url) => fetch(url, {
            method: 'POST',
            headers: {
                'cf-turnstile-response': window.turnstileToken
            }
        }).then(res => res.json())
    )
    console.log("hig data", data)
    if (isLoading) return <CircularProgress isIndeterminate color='green.300'/>
    if (error) return <Error/>
    if (!data || data.length === 0 ) return null;
    return (
        <VStack alignItems={"flex-start"}>
            <Heading as='h6' size='xs'>
                Highlights
            </Heading>
            <Box
                mt={4}
                mb={4}
                overflowX="auto"
                width="100%"
                css={{
                    '&::-webkit-scrollbar': {
                        height: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                    },
                }}
            >
                <Flex flexWrap="nowrap" pb={2}>
                {data.map(highlight => (<HighlightItem highlight={highlight.node} key={highlight.node.id}/>))}
                </Flex>
            </Box>
        </VStack>
    )
}

export default Highlights