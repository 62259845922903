import './App.css';
import {
    Box, Button,
    ChakraProvider, HStack, Input
} from '@chakra-ui/react'
import InstaTab from "./Components/Tab";
import Highlights from "./Components/Highlights";
import {useEffect, useState} from "react";
import Info from "./Components/Info";
import {SWRConfig} from "swr";
import Private from "./Components/Private";
import ReactGA from "react-ga4";
import Turnstile from 'react-turnstile';
import { useCallback } from 'react';
// Initialize ReactGA outside of the component
ReactGA.initialize("G-EYW7F5PNKG");

function App() {
    const [username, setUsername] = useState(null);
    const [privateState, setPrivateState] = useState(-1);
    const [inputValue, setInputValue] = useState('');

    const [turnstileToken, setTurnstileToken] = useState(null);

    const handleTurnstileSuccess = useCallback((token) => {
        setTurnstileToken(token);
    }, []);


    useEffect(() => {
        // Add meta tag for iOS Smart App Banner
        const metaTag = document.createElement('meta');
        metaTag.name = 'apple-itunes-app';
        metaTag.content = 'app-id=6737749873';
        document.head.appendChild(metaTag);

        // Get the current pathname
        const pathname = window.location.pathname;

        // Extract the profileName from the pathname
        const pathParts = pathname.split('/u/');
        const username = pathParts[1];
        setUsername(username);
        setInputValue(username)
        console.log(username)

        // Set the page title
        document.title = username ? `${username}'s Instagram Profile` : 'Instagram Profile Viewer';

        // Cleanup
        return () => {
            document.head.removeChild(metaTag);
        };
    }, []);

    useEffect(() => {
        // Update the page title when username changes
        if (username) {
            document.title = `${username}'s Instagram Profile`;
        }
    }, [username]);

    function handleSubmit(e) {
        e.preventDefault();
        let value = inputValue.replace("@", "");
        setUsername(value);
        setPrivateState(-1);

        // Get the current URL's search params
        const searchParams = new URLSearchParams(window.location.search);
        
        // Construct the new URL
        let newUrl = "/u/" + value;
        console.log(searchParams.toString())

        // If there are any search params, append them to the new URL
        if (searchParams.toString()) {
            newUrl += "?" + searchParams.toString();
        }

        window.history.pushState({}, null, newUrl);
    }

    const customFetcher = async (url) => {
        const res = await fetch(url, {
          headers: {
            'cf-turnstile-response': turnstileToken
          },
        });
      
        if (!res.ok) {
          throw new Error('An error occurred while fetching the data.');
        }

        return res.json();
    };

    return (
        <ChakraProvider>
            <div className="App">
                <SWRConfig
                    value={{
                        refreshInterval: 0,
                        revalidateFirstPage: false,
                        fetcher: customFetcher,
                        dedupingInterval: 30000
                    }}
                >
                    <Box mt={16} p={{base: 1, md: 4}} maxW="1000px" mx="auto">
                        <form onSubmit={handleSubmit}>
                            <HStack>
                                <Input name={"instagram_username"}
                                       value={inputValue}
                                       size={"lg"}
                                       colorScheme='purple'
                                       borderColor={"purple"}
                                       onChange={(e) => setInputValue(e.target.value)} mt={10} mb={10}
                                       placeholder='Enter Instagram username'/>
                                <Button
                                    loadingText='Loading'
                                    colorScheme='purple'
                                    size={"lg"}
                                    spinnerPlacement='start'
                                    type={"submit"}
                                    isDisabled={!turnstileToken}
                                >
                                    Submit
                                </Button>
                            </HStack>
                        </form>
                        <Turnstile
                        sitekey="0x4AAAAAAAxp7urJz122bGkP"
                        onSuccess={handleTurnstileSuccess}
                        refreshExpired='auto'
                        onExpire={() => setTurnstileToken(null)}
                    />
                        {turnstileToken && (
                            <>
                                {(username !== undefined && username !== null) && (
                                    <Info p={6} username={username} setPrivateState={setPrivateState} />
                                )}
                                {(privateState === 1) && (<Private/>)}
                                {(privateState === 0 && username !== undefined && username !== null) && (
                                    <Highlights p={6} username={username}/>
                                )}
                                {(privateState === 0 && username !== undefined && username !== null) && (
                                    <InstaTab username={username}/>
                                )}
                            </>
                        )}


                    </Box>
                </SWRConfig>
            </div>
        </ChakraProvider>
    );
}

export default App;
